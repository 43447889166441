import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  Header,
  FeaturedPosts,
  RecentPosts,
  AnnualReportArchive,
  AllPosts,
} from "../components/Blogs";
import { graphql } from "gatsby";

const BlogPage = ({ data }) => {
  console.log(data);
  return (
    <div>
      <Navbar />

      <Header />
      {/*<FeaturedPosts />
      <RecentPosts />
      <AnnualReportArchive />*/}
      <AllPosts posts={data.posts.posts} />

      <Footer />
    </div>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogQuery {
    posts {
      posts {
        author {
          avatar
          designation
          name
          email
          twitter
        }
        content
        featured_image
        read_time
        slug
        subtitle
        thumbnail
        title
      }
    }
  }
`;
