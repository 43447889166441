import React from "react";

const Header: React.FC = () => (
  <div className="flex flex-col gap-y-4 p-5 max-w-screen-xl w-full my-20 mx-auto">
    <div className="text-4xl sm:text-6xl text-mca-grey font-extrabold">
      <span className="text-mca-pink">Blogs</span> and{" "}
      <span className="text-mca-green">Publications</span>
    </div>
    <div className="text-2xl sm:text-3xl text-mca-grey-2 font-bold">
      Fact checking your news,
      <br />
      one source at a time.
    </div>
  </div>
);

export default Header;
