import React from "react";
import recentUpdates from "../../data/landing/recent-updates";
import BlogPost from "../BlogPost";

const AllPosts: React.FC<{ posts: { [key: string]: string }[] }> = ({
  posts,
}) => (
  <div className="my-16 sm:my-20 relative max-w-screen-xl w-full mx-auto px-5">
    <div className="text-3xl sm:text-5xl text-mca-grey font-bold text-center">
      All <span className="text-mca-green">Posts</span>
    </div>
    <div className="flex flex-col sm:flex-row sm:gap-x-10 sm:gap-y-0">
      <div className="sm:w-1/4 mt-10 grid sm:flex flex-col sm:gap-y-10 gap-x-5 grid-cols-2 !hidden">
        <div>
          <div className="text-mca-grey uppercase font-bold text-lg sm:text-xl">
            Category
          </div>
          <div className="flex flex-col gap-y-2 my-4">
            {[
              { label: "Finance" },
              { label: "Media" },
              { label: "Literature" },
              { label: "Politics" },
              { label: "Philosophy" },
              { label: "Media" },
              { label: "Entrepreneurship" },
            ].map(({ label }, i) => (
              <div
                className="flex gap-x-2 items-center font-bold text-mca-grey-2 text-md sm:text-lg"
                key={i}
              >
                <input
                  type="checkbox"
                  className="form-checkbox p-[8px] border-2 border-mca-grey-2 rounded text-mca-green active:outline-mca-green focus:outline-mca-green"
                />
                <span>{label}</span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="text-mca-grey uppercase font-bold text-lg sm:text-xl">
            Content Type
          </div>
          <div className="flex flex-col gap-y-2 my-4">
            {[
              { label: "Blog Posts" },
              { label: "Updates" },
              { label: "News Articles" },
            ].map(({ label }, i) => (
              <div
                className="flex gap-x-2 items-center font-bold text-mca-grey-2 text-md sm:text-lg"
                key={i}
              >
                <input
                  type="checkbox"
                  className="form-checkbox p-[8px] border-2 border-mca-grey-2 rounded text-mca-green active:outline-mca-green focus:outline-mca-green"
                />
                <span>{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex-1 grid grid-cols-1 sm:grid-cols-3 sm:gap-x-10 gap-y-10 py-10">
        {posts.map(
          (
            {
              featured_image,
              read_time,
              slug,
              thumbnail,
              subtitle,
              title,
              author,
            },
            i
          ) => (
            <BlogPost
              post={{
                image: thumbnail,
                title: title,
                readingTime: read_time,
                author: {
                  name: author?.name,
                  image: author?.avatar,
                },
                slug,
              }}
              key={i}
            />
          )
        )}
      </div>
    </div>
  </div>
);

export default AllPosts;
