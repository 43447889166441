import React from "react";
import { BlogPost } from "../types";

type Props = {
  post: BlogPost;
};
export default function ({
  post: { image, title, readingTime, author, slug },
}: Props) {
  // console.log({ image, title, readingTime, author });
  return (
    <a
      href={slug ? `/blogs/${slug}` : ""}
      className="block rounded-lg overflow-hidden bg-[#fafafa]"
    >
      <img src={image} alt={title} className="h-48 w-full object-cover" />
      <div className="p-5 flex flex-col gap-y-3">
        <div className="text-gray-400 text-sm uppercase">
          {readingTime} min read
        </div>
        <div className="text-mca-grey font-extrabold text-xl">{title}</div>
        <div className="flex gap-x-2 items-center text-gray-400 font-bold uppercase">
          <img
            src={author.image}
            alt={author.name}
            className="h-5 w-5 rounded-full"
          />
          {author.name}
        </div>
      </div>
    </a>
  );
}
